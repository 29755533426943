@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}


.App {
 width: 100%;
 background-color: black;
}

.banner{
  width: 100%;
  height: auto;
}


.banner__video{
  width: 100%;
}

.banner__video-gif{
  width: 100%;
}

.promotion__image{
  background: var(--clr-primary-400);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);

  height:auto;
}

@media screen and (max-width:867px) {

  .promotion__image{
    width: 300px;
  }
  
}

.promotions{
  width: 50%;
  display: grid;
margin: 0 auto;
grid-template-rows: auto auto;
}

@media screen and (max-width:867px) {

  .promotions{
    width: 100%;
  }
  
}

.promotions__container{

  -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  overflow:hidden;
  max-width: 100%;
}

.promotions__images-container{
  padding-block:1rem;
  display:flex;
  animation: scroll linear 9s infinite;
  gap: 1rem;
  width: max-content;
}

@media screen and (max-width:867px) {
  .promotions__images-container{
    animation: scroll linear 10s infinite;


  }
  
}

@media screen and (max-width:867px) {
  .promotions__images-container{
    
    animation: scroll linear 10s infinite;
    


  }
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

.promotions__title{
  color: white;
  text-align: center;
  font-weight: bolder;
  font-size: 30px;
  width: 100%;
}


.footer__container{
  padding: 50px 0 50px 0;
  width: 100%;
  color: gold;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  text-align: center;
}